/*------------------------------>
	Site > Modules > Plugins
<------------------------------*/

	Site.Modules.Plugins = (function($, Site) {

		function init() {

			// Subnavigation
			/*
			Site.State.$body.find('.js-subnavigation').navigation({
				theme: "fs-light"
			});
			*/

			// Backgrounds
			Site.State.$body.find(".js-background").background();

			// Equalize
			Site.State.$body.find('.js-equalize').equalize();

			// Lightbox
			Site.State.$body.find(".js-lightbox").lightbox();
			
			// Carousels
			Site.State.$body.find(".js-carousel").carousel({
				theme: "fs-light"
			});

			// Forms
			$("input[type=checkbox], input[type=radio]").checkbox();


			// Tables
			$('table').wrap( "<div class='table-fade'></div>" ).wrap("<div class='table-wrapper'></div>");

			// Schools
			var $schools = Site.State.$body.find('.js-school-button');

			$schools.on('click', function(e) {
				e.preventDefault();
				var active = false, $this = $(this);
				if ($this.hasClass('active') ) {
					active = true;
				}
				$schools.removeClass('active');
				if (!active) {
					$this.addClass('active');
				}
			});
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);