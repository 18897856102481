/*------------------------------>
	Site > Modules > Navigation
<------------------------------*/

	Site.Modules.Navigation = (function($, Site) {

		function init() {
			var $hidden = Site.State.$body.find(".js-navigation-hidden");

			// Main Navigation
			Site.State.$body.find(".js-navigation-button").on("click", function (e) {
				e.preventDefault();
				e.stopPropagation();

				var $button = $(this);

				$button.toggleClass("open");
				$hidden.toggleClass("show");

				if ($button.hasClass("open")) {
					$button.text($button.data("name-open"));
				} else {
					$button.text($button.data("name-closed"));
				}
			});

			Site.State.$body.find(".js-navigation-expand").on("click", function (e) {
				e.preventDefault();
				e.stopPropagation();

				var $button = $(this),
					$parent = $button.parent(".js-navigation-expand-parent");
				
				$parent.parent().find(".js-navigation-expand-parent").not($parent).removeClass("open");
				$parent.toggleClass("open");
			});
		}

		Site.InitFunctions.push(init);

	})(jQuery, Site);