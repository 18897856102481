/*------------------------------>
	Site
<------------------------------*/

	var IE8 = IE8 || false,
	    IE9 = IE9 || false;

	var Site = (function($, window) {
		this.Modules = {};
		this.State   = {};

		this.InitFunctions    = [];
		this.RespondFunctions = [];
		this.ResizeFunctions  = [];
		this.ScrollFunctions  = [];

		this.windowWidth  = 0;
		this.windowHeight = 0;
		this.windowData = {};

		this.namespace      = null;

		this.State.window   = null;
		this.State.doc      = null;

		this.State.$window  = null;
		this.State.$doc     = null;
		this.State.$body    = null;

		this.ns = function(text) {
			return text + "." + this.namespace;
		};

		this.iterate = function(items) {
			for (var i in items) {
				if (items.hasOwnProperty(i)) {
					items[i].apply(this, Array.prototype.slice.call(arguments, 1));
				}
			}
		};

		this.init = function (namespace) {
			this.namespace = namespace;

			this.State.window    = window;
			this.State.$window   = $(window);
			this.State.$document = $(document);
			this.State.$body     = $("body");

			if ($.mediaquery) {
				$.mediaquery({
					minWidth: [ this.minXS, this.minSM, this.minMD, this.minLG, this.minXL ],
					maxWidth: [ this.maxXL, this.maxLG, this.maxMD, this.maxSM, this.maxXS ],
					minHeight: [ this.minHTsm, this.minHT ]
				});
			}

			Site.windowData = $.mediaquery("state");

			this.iterate(this.InitFunctions);
		};

		return this;

	})(jQuery, window);

	$(document).ready(function() {
		Site.init("FA");
	});
