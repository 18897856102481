/*------------------------------>
	Site > Modules > Events
<------------------------------*/

	Site.Modules.Events = (function($, Site) {
		var $datepicker = null,
			startDate = null,
			range = null,
			datepickerArgs = {},
			pageURL = null;

 		function changeDate(date, ui) {
			window.location = pageURL + 'day/' + date;
		}

		function init() {
			$datepicker = Site.State.$body.find(".datepicker");
			pageURL = $datepicker.data("path");
			
			datepickerArgs = {
				dayNamesMin: ['SUN', 'MON', 'TUES', 'WED', 'THUR', 'FRI', 'SAT'],
				dateFormat: 'yy-mm-dd',
				numberOfMonths: 2,
				stepMonths: 1,
				onSelect: changeDate,
				defaultDate: $datepicker.data("start-date")
			}

			onRespond();
		}

		function onRespond() {
			$datepicker.datepicker("destroy");
			$datepicker.removeClass("hasDatepicker").removeAttr("id");

			if ( Site.windowData.maxWidth < 740 ) {
				datepickerArgs.numberOfMonths = 1;
			} else {
				datepickerArgs.numberOfMonths = 2;
			}

			$datepicker.datepicker(datepickerArgs);
		}

		Site.RespondFunctions.push(onRespond);

		Site.InitFunctions.push(init);

	})(jQuery, Site);